export const generateRandomId = () => {
  const rand =
    1000 + (Math.floor(Math.random() * /* uint32 */ 4294967296) % 8999);
  return `${Date.now()}${rand}`;
};

export const generateSessionId = () => {
  const rand =
    1000 + (Math.floor(Math.random() * /* uint32 */ 4294967296) % 8999);
  return `${rand}${Math.floor(Date.now() / 1000)}`;
};
