/* eslint-disable react/prop-types */
import React from 'react';
import logo from '@/assets/logo.png';
import { PlayerConfig } from '@/constants';
import styles from './index.module.scss';

export default ({ onDownload }) => {
  return (
    <div className={styles.Header}>
      <div className={styles.left}>
        <img className={styles.logo} src={logo} alt="" />
        <div>
          <p className={styles.name}>{PlayerConfig.name}</p>
          <p className={styles.fullName}>{PlayerConfig.fullName}</p>
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.btn} onClick={onDownload} data-agl-cvt="6">
          <span>立即下载</span>
        </div>
      </div>
    </div>
  );
};
