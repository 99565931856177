import bee from '@/services/bee';

/**
 * 点击下载-用户点击且操作成功时上报 
 * @param {Object} payload - 点位对象
 * @param {string} payload.ev - 项翼: 点击下载位置 1：顶部栏："立即下载"按钮 2：页底："官方下载"按钮
 * @param {string} payload.oid - 项翼: 推广页软件名称，如KMPalyer
 * @param {string} payload.method - 上报方式
*/
export function evtkClickDownloadInAdsPage(payload = {}) {
  return bee[payload.method || 'track']({
    ea: 'click_download',
    ec: 'click',
    ev: payload.ev,    // 项翼: 点击下载位置 1：顶部栏："立即下载"按钮 2：页底："官方下载"按钮
    oid: payload.oid,    // 项翼: 推广页软件名称，如KMPalyer
    tid: 'ads_page',
  });
  // ads_page.click.click_download.PRSCD-45 - 搜索索引
}

/**
 * 曝光通用下载器推广页面-页面曝光时上报 
 * @param {Object} payload - 点位对象
 * @param {string} payload.oid - 项翼: 推广页软件名称，如KMPalyer
 * @param {string} payload.method - 上报方式
*/
export function evtkExposeAdsPageInAdsPage(payload = {}) {
  return bee[payload.method || 'track']({
    ea: 'expose_ads_page',
    ec: 'expose',
    oid: payload.oid,    // 项翼: 推广页软件名称，如KMPalyer
    tid: 'ads_page',
  });
  // ads_page.expose.expose_ads_page.PRSCD-45 - 搜索索引
}

