/* eslint-disable max-lines-per-function */
import React, { useEffect } from 'react';
import type { NextPage } from 'next';
import Link from 'next/link';
import { PlayerConfig } from '@/constants';
import Header from '@/components/header';
import { evtkExposeAdsPageInAdsPage, evtkClickDownloadInAdsPage } from '@/dots';
import { softwareHeart } from '@/utils/business';
import styles from './index.module.scss';

const Home: NextPage = () => {
  const handleDownload = (ev: string) => {
    evtkClickDownloadInAdsPage({
      ev,
      oid: PlayerConfig.name,
      method: 'trackSync'
    });

    softwareHeart.getUrl().then((url: string) => {
      if (url) {
        window.open(url, '_blank');
      }
    });
  };

  useEffect(() => {
    softwareHeart.start();

    evtkExposeAdsPageInAdsPage({
      method: 'trackSync',
      oid: PlayerConfig.name
    });

    return () => { };
  }, []);

  return (
    <div className={styles.App}>
      <Header onDownload={() => handleDownload('1')} />
      <div className={styles.content}>
        <div className={styles.left}>
          <div className={styles.title}>{PlayerConfig.name}</div>
          <div className={styles.fullName}>{PlayerConfig.fullName}</div>
          <div className={styles.descs}>
            {PlayerConfig.desc}
          </div>
          <div className={styles.btn} data-agl-cvt="6" onClick={() => handleDownload('2')}>
            <span>官方下载</span>
          </div>
          <div className={styles.terms}>
            开发者{PlayerConfig.name} | <Link target="_blank" href="/privacy-agreement">用户权限</Link>
          </div>
          <div className={styles.version}>
            版本号：{PlayerConfig.version}丨 最新版本更新：{PlayerConfig.updateTime}
          </div>
          <div className={styles.corp}>
            {PlayerConfig.companyNameAndRecordNumber}
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.boxShow} />
        </div>
      </div>

      <script
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `
          window._agl = window._agl || [];
          (function () {
          _agl.push(
          ['production', '_f7L2XwGXjyszb4d1e2oxPybgD']
          );
          (function () {
          var agl = document.createElement('script');
          agl.type = 'text/javascript';
          agl.async = true;
          agl.src = 'https://fxgate.baidu.com/angelia/fcagl.js?production=_f7L2XwGXjyszb4d1e2oxPybgD';
          var s = document.getElementsByTagName('script')[0];
          s.parentNode.insertBefore(agl, s);
          })();
          })();
        `,
        }}
      />
      <script
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `
          var _hmt = _hmt || [];
          (function() {
            var hm = document.createElement("script");
            hm.src = "https://hm.baidu.com/hm.js?405782da0c22d094c23ff1fc031461c4";
            var s = document.getElementsByTagName("script")[0]; 
            s.parentNode.insertBefore(hm, s);
          })();
        `,
        }}
      />
      {/* 360统计代码 */}
      <script
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `
          (function(b,a,e,h,f,c,g,s){b[h]=b[h]||function(){(b[h].c=b[h].c||[]).push(arguments)};
          b[h].s=!!c;g=a.getElementsByTagName(e)[0];s=a.createElement(e);
          s.src="//s.union.360.cn/"+f+".js";s.defer=!0;s.async=!0;g.parentNode.insertBefore(s,g)
          })(window,document,"script","_qha",531533,false);`
        }}
      />
    </div>
  );
};

export default Home;
