export const isWeixin = () => {
  return /MicroMessenger|WeXin|WeChat/g.test(navigator.userAgent);
};

export const getWindowConfig = () => {
  let windowWidth = window.innerWidth;
  let windowHeight = window.innerHeight;
  if (typeof windowWidth !== 'number') {
    if (document.compatMode === 'CSS1Compat') {
      windowWidth = document.documentElement.clientWidth;
      windowHeight = document.documentElement.clientHeight;
    } else {
      windowWidth = document.body.clientWidth;
      windowHeight = window.body.clientHeight;
    }
  }
  return {
    w: windowWidth,
    h: windowHeight,
  };
};

export const formatPx = (val) => {
  return (document.documentElement.clientWidth / 375) * val;
};  

export const addZero = (num) => {
  return num < 10 ? `0${num}` : num;
};
const safeCall = (call) => {
    try {
      return call();
    // eslint-disable-next-line no-empty
    } catch (e) { }
  
    return '';
  };
export function getUrlParam(name, url) {
    const theUrl = url || safeCall(() => window.location.search);
    const safeName = name.replace(/[\\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp(`[\\?&]${safeName}=([^&#]*)`);
    const results = regex.exec(theUrl);
    return results === null
      ? ''
      : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }
export default isWeixin;
