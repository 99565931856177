import { getSoftware, heart } from '@/services/brushing';
import { PlayerConfig } from '../constants';
import { getUrlParam } from './util';

export const softwareHeart = {
  token: '',
  timer: null,
  tokenEnsurePromise: null,
  heartOnce() {
    return heart().then((res) => {
      this.token = res?.token;

      return Promise.resolve(this.token);
    });
  },
  start() {
    clearInterval(this.timer);
    this.tokenEnsurePromise = this.heartOnce();
    this.timer = setInterval(() => {
      this.heartOnce();
    }, 1000 * 55);
  },
  getUrl() {
    if (!this.tokenEnsurePromise) {
      this.tokenEnsurePromise = this.heartOnce();
    }

    return this.tokenEnsurePromise.then(() => {
      return getSoftware({ product: PlayerConfig.product }, this.token).then((res) => {
        const dlbdVid = getUrlParam('bd_vid') || getUrlParam('qhclickid');

        if (res?.url && dlbdVid) {
          const signal = res?.url.indexOf('?') > -1 ? '&' : '?';
          return `${res.url}${signal}dlbd_vid=${dlbdVid}`;
        }

        return res?.url;
      });
    });
  }
};

