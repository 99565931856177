/* eslint-disable no-param-reassign */
import { get, post } from '@/utils/request';

/**
 * 心跳接口
 * @returns 
 */
export const heart = () => {
  return post(`${process.env.NODE_ENV === 'development' ? '' : process.env.NEXT_PUBLIC_API_HOST  }/v3/software/heart`);
};

/**
 * 获取下载地址
 * @param {object} params 参数
 * @param {string} params.product 产品 pot或pot2
 * @param {string} token token
 * @returns 
 */
export const getSoftware = (params, token) => {
  params.product = params.product || 'pot';

  return get(
    `${process.env.NODE_ENV === 'development' ? '' : process.env.NEXT_PUBLIC_API_HOST  }/v3/software/downloadUrl`
    , params
    , {
      headers: {
        token: token || ''
      }
    }
  );
};
