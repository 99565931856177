import { generateRandomId } from '@/utils/env';

class Did {
  did = 0;

  constructor() {
    this.getValue();
  }

  getDeviceId() {
    if (typeof window !== 'object' || !window.localStorage) {
      return this.did;
    }

    let did = localStorage.getItem('global/did');

    if (!did) {
      did = generateRandomId();
      localStorage.setItem('global/did', did);
    }

    return did;
  }

  getValue() {
    if (this.did) return this.did;

    this.did = this.getDeviceId();

    return this.did;
  }
}

export default new Did();
