/* eslint-disable max-len */
/**
 * Player 信息
 */
export const PlayerConfig = {
  name: 'OBS',
  fullName: 'Open Broadcaster Software', // 全称
  desc: 'OBS（Open Broadcaster Software）是一款开源的电视直播和流媒体制作软件，支持多种视频格式，提供丰富的特效和滤镜，并可以添加图片、文字、音频等元素。虽然操作界面较为专业，但通过在线文档、社区支持和视频教程，可以帮助新手快速上手并了解其高级功能。',
  version: '1.0.1',
  updateTime: '2023-08-11',
  companyNameAndRecordNumber: '上海日维网络科技有限公司｜沪ICP备2023004882号-7',
  channel: 'obs_02',
  product: 'dlobs_02'
};

export default PlayerConfig;
