import React from 'react';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import logo from '@/assets/logo.png';
import { PlayerConfig } from '@/constants';
import '../styles/globals.css';

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <title>{PlayerConfig.name}</title>
        <meta name="description" content={PlayerConfig.name} />
        <link rel="icon" href={logo} />
      </Head>
      <Component {...pageProps} />
    </>
  );
}

export default MyApp;
