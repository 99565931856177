/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import BeeTracker from '@cl/bee-tracker';
import did from '@/services/did';
import { generateSessionId } from '@/utils/env';
import { PlayerConfig } from '../constants';

const bee = new BeeTracker();

bee.setHost('https://dot.coldlake1.com/dot/b.gif');
bee.setUserDefaults({
  t: 'event',
  el: 'user',
  aid: 'down',
  did: did.getValue(),
  uid: () => '',
  sid: () => '',
  asid: generateSessionId(),
  bid: 'down',
  channel: PlayerConfig.channel,
});

bee.ready();

export default bee;
